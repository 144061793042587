import { React, useState, useEffect } from 'react';

//UI
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PasswordField from '../components/PasswordField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

//GLOBALS
var styles = require('../styles.js');
const ButtonPin = withStyles(styles.ButtonPin)(Button);
const ButtonFlat = withStyles(styles.ButtonFlat)(Button);

let pin_code = "";
const button_spacing = 1;
let timeout = 60;
let time = timeout;

//METHODS
export default function Users(props) {
  const [loading, setLoading] = useState(false);
  const [pinValue, setPinValue] = useState("");
  const [remainingTime, setRemainingTime] = useState(timeout);

  function handleSetPinValue(data) {
    pin_code = pinValue.concat(data.value);
    setPinValue(pin_code);
  }

  function handleDelPinValue() {
    pin_code = pinValue.slice(0, -1);
    setPinValue(pin_code);
  }

  async function handleValidateCode() {
    setLoading(true);
    setRemainingTime(10);
    let keyStatus = (await props.setKey(pin_code));

    if (keyStatus.status === 'New')
      props.setSnackBar({ open: true, message: "Error: " + props.texts.messages.error_key_in_use.replace("$key", pin_code), severity: "error" });
    else if (keyStatus.status !== 'Invalid')
      props.nextState();
    else
      props.setSnackBar({ open: true, message: "Error: " + props.texts.messages.key_not_valid.replace("$key", pin_code), severity: "error" });

    setLoading(false);
  }

  useEffect(() => {
    const timer = setInterval(() => {
      time--;
      setRemainingTime(time);

      if (time === 0) {
        props.prevState();
        clearInterval(timer);
      }
    }, 1000);
    return () => { time = timeout; clearInterval(timer); }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="pin-menu">
      <div className="left-menu">
        <div />
        {!loading && <Grid>
          <Grid container justifyContent="center" spacing={button_spacing}>
            {[1, 2, 3].map((value) => (
              <Grid key={value} item>
                <ButtonPin className="no-focus-button" onClick={() => handleSetPinValue({ value })}>{value}</ButtonPin>
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="center" spacing={button_spacing}>
            {[4, 5, 6].map((value) => (
              <Grid key={value} item>
                <ButtonPin className="no-focus-button" onClick={() => handleSetPinValue({ value })}>{value}</ButtonPin>
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="center" spacing={button_spacing}>
            {[7, 8, 9].map((value) => (
              <Grid key={value} item>
                <ButtonPin className="no-focus-button" onClick={() => handleSetPinValue({ value })}>{value}</ButtonPin>
              </Grid>
            ))}
          </Grid>
          <Grid container justifyContent="center" spacing={button_spacing}>
            <Grid key="DEL" item>
              <ButtonPin style={{ fontSize: '5vmin' }} className="no-focus-button" onClick={() => handleDelPinValue()}>DEL</ButtonPin>
            </Grid>
            <Grid key="0" item>
              <ButtonPin className="no-focus-button" onClick={() => handleSetPinValue({ value: "0" })}>0</ButtonPin>
            </Grid>
            <Grid key="CLR" item>
              <ButtonPin style={{ fontSize: '5vmin' }} className="no-focus-button" onClick={() => setPinValue("")}>CLR</ButtonPin>
            </Grid>
          </Grid>
        </Grid>}
        {!loading &&
          <Typography variant="subtitle1" color="inherit" display="inline" align="center">
            {props.texts.messages.window_will_close.replace("$time", remainingTime)}
          </Typography>}
        {loading && <CircularProgress size='14vmin' style={{ color: 'rgb(0,2,58)' }} />}
        <div />
      </div>

      <div>
        <div className="button-menu">
          <PasswordField label={pinValue} texts={props.texts} />
        </div>

        <div className="button-menu">
          <ButtonFlat disabled={pinValue.length !== 6} onClick={() => handleValidateCode()} style={{ background: pinValue.length === 6 ? "rgb(16,158,154)" : "" }}>
            {props.texts.buttons.confirm.toUpperCase()}
          </ButtonFlat>
        </div>

        <div className="button-menu">
          <ButtonFlat onClick={props.prevState}>
            {props.texts.buttons.cancel.toUpperCase()}
          </ButtonFlat>
        </div>
      </div>

      <div />
    </div>);
}