//UTILS
import { React, useEffect, useState } from 'react';

//UI
import '../App.css';
import Typography from '@material-ui/core/Typography';
import mainLogo from '../images/main-logo-2.png';

//CUSTOM REQUIRES
var styles = require('../styles.js');

//METHODS
export default function KeyNotValid(props) {
  const [seconds, setSeconds] = useState(4);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0)
        setSeconds(seconds => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  if (seconds === 0) {
    setSeconds(-1);
    props.goHome();
  }

  function userMessage(status) {
    switch (status) {
      case 'Blocked':
        return props.texts.messages.locker_blocked;
      case 'Invalid':
      default:
        return props.texts.messages.key_not_valid;
    }
  }

  return (
    <div className="widget-menu">
      <div></div>

      <div className="main-menu">
        <div className="logo-menu">
          <img className="ilock-app-logo-mini" src={mainLogo} alt="ilock-app-logo-mini" />
          <div className="button-menu">
            <Typography variant="h1" color="inherit" display="inline" align="center" className="info-widget">
              {userMessage(props.status)}
            </Typography>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
}
