//GLOBALS
const url = 'http://localhost:8080';
const verbose = false;

//METHODS
//- - - - - - - - - - - -- - - - - - - 
//- - - - - - - STATIONS - - - - - - - 
//- - - - - - - - - - - -- - - - - - - 
//POST http://localhost:8080/Station
export async function setStation(stationInfo) {  
  if (verbose) console.log('* HTTP: > > POST > > /Station')
  try {    
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(stationInfo)
    };
    await fetch(url + '/Station', requestOptions);        
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - setStation> error:" + e);
  }
}

export async function updateStation(stationInfo) {  
  if (verbose) console.log('* HTTP: > > PUT > > /Station')
  try {    
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(stationInfo)
    };
    await fetch(url + '/Station', requestOptions);        
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - updateStation> error:" + e);
  }
}

//GET http://localhost:8080/Station
export async function getStation() {
  if (verbose) console.log('* HTTP: > > GET > > /Station')
  try {    
    const headers = { 'Content-Type': 'application/json'}
    const response = await fetch(url + '/Station', { headers });
    const data = await response.json();
    return data;
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - getStation> error:" + e);    
  }
}

//- - - - - - - - - - - - - - - - - 
//- - - - - - TIME  - - - - - - - - 
//- - - - - - - - - - - - - - - - - 
//GET http://localhost:8080/Time
export async function getTime() {
  if (verbose) console.log('* HTTP: > > GET > > /Time')
  try {    
    const headers = { 'Content-Type': 'application/json'}
    const response = await fetch(url + '/Time', { headers });
    const data = await response.json();    
    return data;
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - getTime> error:" + e);    
  }
}
//PUT http://localhost:8080/Time
export async function setTime(time) {  
  if (verbose) console.log('* HTTP: > > PUT > > /Time')
  try {    
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: time
    };
    await fetch(url + '/Time', requestOptions);    
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - setTime> error:" + e);
  }
} 

//- - - - - - - - - - - - - - - - - 
//- - - - - - - LOCKS - - - - - - - 
//- - - - - - - - - - - - - - - - - 
//PUT http://localhost:8080/Lock
export async function setLock(lockInfo) {    
  if (verbose) console.log('* HTTP: > > PUT > > /Lock')
  try {    
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(lockInfo)
    };
    await fetch(url + '/Lock', requestOptions);    
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - setLock> error:" + e);
    throw(e);    
  }
}
//GET http://localhost:8080/Lock
export async function getLock(num) {
  if (verbose) console.log('* HTTP: > > GET > > /Lock')
  try {    
    const requestOptions = {        
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({id: num})
    };
    const response = await fetch(url + '/Lock', requestOptions);
    const data = await response.json();    
    return data;
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - getLock> error:" + e);
  }
}

//- - - - - - - - - - - - - - - - - 
//- - - - - - - KEYS  - - - - - - - 
//- - - - - - - - - - - - - - - - - 
//GET http://localhost:8080/Key
export async function getKey() {
  if (verbose) console.log('* HTTP: > > GET > > /Key')
  try {    
    const headers = { 'Content-Type': 'application/json'}
    const response = await fetch(url + '/Key', { headers });
    const data = await response.json();    
    return data;
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - getStation> error:" + e);
  }
}
//DELETE http://localhost:8080/Key
export async function deleteKey(keyInfo) {  
  if (verbose) console.log('* HTTP: > > DELETE > > /Key')
  try {    
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(keyInfo)
    };
    await fetch(url + '/Key', requestOptions);    
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - deleteKey> error:" + e);
  }
}
//PUT http://localhost:8080/Key
export async function addKey(keyInfo) {  
  if (verbose) console.log('* HTTP: > > PUT > > /Key')
  try {    
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(keyInfo)
    };
    await fetch(url + '/Key', requestOptions);    
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - addKey> error:" + e);
  }
} 

//- - - - - - - - - - - - - - - - - 
//- - - - - - - ADMIN - - - - - - - 
//- - - - - - - - - - - - - - - - - 
//GET http://localhost:8080/Admin
export async function isAdmin(key) {
  if (verbose) console.log('* HTTP: > > GET > > /Admin ' + key)
  try {    
    const headers = { 'Content-Type': 'application/json'}
    const response = await fetch(url + '/Admin', { headers });
    const data = await response.json();        
    return (key !== "" && data.some( admin => (admin.key === key) || (admin.id === key) || (admin.pin === key)));
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - isAdmin> error:" + e);    
  }
}

//PUT http://localhost:8080/Reboot
export async function reboot() {  
  if (verbose) console.log('* HTTP: > > PUT > > /Reboot')
  try {    
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({dummy: "data"})
    };
    await fetch(url + '/Reboot', requestOptions);
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - reboot> error:" + e);
    throw e;
  }
} 

//PUT http://localhost:8080/Reboot
export async function run(command) {  
  if (verbose) console.log('* HTTP: > > PUT > > /Run')
  try {    
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(command)
    };
    await fetch(url + '/Run', requestOptions);
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - run> error:" + e);
    throw e;
  }
} 

//- - - - - - - - - - - - - - - - - 
//- - - - - - - OPEN - - - - - - - 
//- - - - - - - - - - - - - - - - - 
//PUT http://localhost:8080/Open
export async function openLock(lockInfo) {  
  if (verbose) console.log('* HTTP: > > PUT > > /Open')
  try {    
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(lockInfo)
    };
    await fetch(url + '/Open', requestOptions);    
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - openLock> error:" + e);
    throw e;
  }
} 

//- - - - - - - - - - - - - - - - - 
//- - - - - - - LIGHT  - - - - - - - 
//- - - - - - - - - - - - - - - - - 
//PUT http://localhost:8080/light
export async function lightOn() {  
  if (verbose) console.log('* HTTP: > > PUT > > /Light')
  try {    
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: ""     
    };
    await fetch(url + '/Light', requestOptions);    
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - lightOn> error:" + e);
  }
} 
//DELETE http://localhost:8080/light
export async function lightOff() {  
  if (verbose) console.log('* HTTP: > > DELETE > > /Light')
  try {    
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: ""
    };
    await fetch(url + '/Light', requestOptions);    
  }
  catch(e)
  {
    if (verbose) console.log("<LockServer - lightOff> error:" + e);
  }
}