//UTILS
import React from 'react';

//UI
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

//CUSTOM STYLES
var styles = require('../styles.js');
const ButtonFooter = withStyles(styles.ButtonFooter)(Button);

//METHODS
export default function BottomMenu(props) {

  return (  
    <div className="bottom-menu">
      {!props.hidePrevButton && <ButtonFooter
          disabled={props.disablePrevButton}
          onClick={props.prevState}
        >
          {props.prevText ? props.prevText : props.texts.buttons.cancel.toUpperCase()}
      </ButtonFooter>}

      {!props.hideNextButton && <ButtonFooter
          disabled={props.disableNextButton}
          onClick={props.nextState}
          style={{ background: !props.disableNextButton ? "rgb(16,158,154)" : "" }}
        >
          {props.nextText ? props.nextText : props.texts.buttons.confirm.toUpperCase()}
      </ButtonFooter>}
    </div>);
};