import React, { useState, useEffect, useRef } from 'react';

//UI
import '../App.css';
import Typography from '@material-ui/core/Typography';
import QRlogo from '../images/QRlogo.png';
import RFIDlogo from '../images/RFIDlogo.png';
import BottomMenu from '../components/BottomMenu';
import CircularProgress from '@material-ui/core/CircularProgress';

//CUSTOM REQUIRES
var styles = require('../styles.js');
var lockServer = require('../tools/LockServer.js');
var timer;

//GLOBALS

//METHODS
export default function AddKey(props) {
    const [loading, setLoading] = useState(false);
    const [remainingTime, setRemainingTime] = useState();
    const [key, setKey] = useState("");
    const mainRef = useRef(null);

    function handlePrevState() {
        mainRef.current.blur();
        lockServer.lightOff();
        clearInterval(timer);
        props.prevState();
    }

    async function onKeyPress(e) {
        if (e.key === "Enter" && key !== "") {
            setLoading(true);
            setRemainingTime(10);
            let response = await props.addKey(key);
            if (response?.userUpdated) {
                props.setSnackBar({ open: true, message: props.texts.messages.key_added.replace("$key", key), severity: "success" });
            }
            else if (response?.userError) {
                props.setSnackBar({ open: true, message: "Error: " + response?.userError.error, severity: "error" });
            }
            else {
                props.setSnackBar({ open: true, message: "Error: " + props.texts.messages.key_not_valid, severity: "error" });
            }
            setLoading(false);
            handlePrevState();
        }
        else
            setKey(key + e.key);
    }

    function setTimer() {
        let pendingTime = 60;
        setRemainingTime(pendingTime);
        lockServer.lightOn();
        timer = setInterval(async () => {
            mainRef.current.focus();
            setRemainingTime(pendingTime--);
            if (pendingTime < 0) {
                handlePrevState();
            }
        }, 1000);
        return () => clearInterval(timer);
    }

    useEffect(() => {
        setTimer();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="widget-menu">
            <div></div>

            <div className="center-widget">

                <Typography variant="h1" color="inherit" display="inline" align="center" className="info-widget">
                    {props.texts.messages.scan_qr_or_member}
                </Typography>

                <div>
                    <img src={QRlogo} alt="QRLogo" className="scan-logo" ref={mainRef} onKeyPress={onKeyPress} tabIndex={0} />
                    <img src={RFIDlogo} alt="RFIDlogo" className="scan-logo" />
                </div>

                {!loading &&
                    <Typography variant="subtitle1" color="inherit" display="inline" align="center">
                        {props.texts.messages.window_will_close.replace("$time", remainingTime)}
                    </Typography>}


                {loading && <CircularProgress size='7vmin' style={{ marginTop: '25px', color: 'rgb(0,2,58)' }} />}
            </div>

            <BottomMenu prevState={handlePrevState} hideNextButton={true} texts={props.texts} />

        </div>
    );
}