import { createTheme } from '@material-ui/core/styles';

export const buttonBig = {
  width: '40vmin',
  height: '40vmin',
  justifyContent: "center",
  fontSize: '20vmin',
  backgroundImage: "linear-gradient( 20deg, rgb(16,158,154) 0%, rgb(0,2,58) 100%)", color: 'white'
};

export const lockAdminCard = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  width: '40vmin',
  height: '30vmin',
  boxShadow: '0 2px 4px 1px rgba(105, 105, 105, .3)',
  backgroundImage: "linear-gradient( 20deg, rgb(16,158,154) 0%, rgb(0,2,58) 100%)", color: 'white'
};

export const lockThanksCard = {
  border: '10px',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  width: '50vmin',
  height: '50vmin',
  boxShadow: '0 2px 4px 1px rgba(105, 105, 105, .3)',
  backgroundImage: "linear-gradient( 20deg, rgb(16,158,154) 0%, rgb(0,2,58) 100%)", color: 'white'
};

export const buttonArrow = {
  width: '20vmin',
  height: '30vmin',
  justifyContent: "center"
};

export const ButtonBasic = {
  root: {
    width: '100%',
    height: '18vmin',
    fontSize: '7vmin',
    justifyContent: "center",
    backgroundImage: "linear-gradient( 20deg, rgb(16,158,154) 0%, rgb(0,2,58) 100%)",
    color: 'white',
    boxShadow: '0 2px 4px 1px rgba(105, 105, 105, .3)',
    '&:disabled': {
      backgroundImage: "linear-gradient( 20deg, rgb(220,220,220) 0%, rgb(140,140,140) 100%)",
    }
  },
  label: {
    textTransform: 'capitalize',
  },
};

export const ButtonFlat = {
  root: {
    width: '100%',
    height: '18vmin',
    fontSize: '7vmin',
    justifyContent: "center",
    backgroundColor: "rgb(0,2,58)",
    color: 'white',
    '&:disabled': {
      backgroundColor: "rgb(180,180,180)",
    },
    '&:hover': {
      backgroundColor: "rgb(0,2,58)",
    }
  },
  label: {
    textTransform: 'capitalize',
  },
};

export const ButtonModal = {
  root: {
    // width: '100%',
    // height: '12vmin',
    fontSize: '5vmin',
    margin: '2vmin',
    padding: '2vmin',
    justifyContent: 'center',
    textAlign: 'center',
    backgroundImage: "linear-gradient( 20deg, rgb(16,158,154) 0%, rgb(0,2,58) 100%)",
    color: 'white',
    boxShadow: '0 2px 4px 1px rgba(105, 105, 105, .3)',
    '&:disabled': {
      backgroundImage: "linear-gradient( 20deg, rgb(220,220,220) 0%, rgb(140,140,140) 100%)",
    }
  },
  label: {
    textTransform: 'capitalize',
  },
};

export const ButtonFooter = {
  root: {
    width: '45%',
    height: '13vmin',
    margin: "4%",
    fontSize: '5vmin',
    backgroundColor: "rgb(0,2,58)",
    color: 'white',
    '&:disabled': {
      backgroundColor: "rgb(180,180,180)",
    },
    '&:hover': {
      backgroundColor: "rgb(0,2,58)",
    }
  },
  label: {
    textTransform: 'capitalize',
  },
};

export const ButtonFooterMenu = {
  root: {
    width: '45%',
    height: '13vmin',
    margin: "4%",
    fontSize: '5vmin',
    backgroundColor: "rgb(0,2,58)",
    color: 'white',
    '&:disabled': {
      backgroundColor: "rgb(180,180,180)",
    },
    '&:hover': {
      backgroundColor: "rgb(0,2,58)",
    }
  },
  label: {
    textTransform: 'capitalize',
  },
};

export const ButtonAdmin = {
  root: {
    width: '100%',
    height: '10vmin',
    fontSize: '5vmin',
    justifyContent: "center",
    background: "rgb(16,158,154)",
    color: 'white',
    boxShadow: '0 2px 4px 1px rgba(105, 105, 105, .3)',
    '&:disabled': {
      backgroundImage: "linear-gradient( 20deg, rgb(220,220,220) 0%, rgb(140,140,140) 100%)",
    }
  },
  label: {
    textTransform: 'capitalize',
  },
};

export const ButtonPin = {
  root: {
    width: '14vmin',
    height: '14vmin',
    justifyContent: "center",
    fontSize: '7vmin',
    textAlign: "center",
    backgroundColor: "rgb(16,158,154)",
    color: 'white',
    boxShadow: '0 2px 4px 1px rgba(105, 105, 105, .3)'
  },
  label: {
    textTransform: 'capitalize',
  },
};

export const ButtonLock = {
  root: {
    width: '14vmin',
    height: '10vmin',
    justifyContent: "center",
    fontSize: '5vmin',
    textAlign: "center",
    backgroundColor: "rgb(16,158,154)",
    color: 'white',
    boxShadow: '0 2px 4px 1px rgba(105, 105, 105, .3)',
    '&:disabled': {
      backgroundImage: "transparent",
      boxShadow: '0 0px 0px 0px',
    }
  },
  label: {
    textTransform: 'capitalize',
  },
};

export const StepLabel = {
  label: {
    fontSize: '2.5vmin'
  }
};

export const theme = createTheme({
  typography: {
    button: {
      fontSize: '10vmin'
    },
    h1: {
      fontSize: '5vmin'
    },
    h2: {
      fontSize: '4vmin'
    },
    h3: {
      fontSize: '3.5vmin'
    },
    subtitle1: {
      fontSize: '2.5vmin'
    }
  },
});