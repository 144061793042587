//UTILS
import React, { useEffect, useState } from 'react';

//UI
import '../App.css';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import BottomMenu from '../components/BottomMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockersGrid from '../components/LockersGrid';

//CUSTOM STYLES
var styles = require('../styles.js');

//GLOBALS
let timeout = 60;
let time = timeout;
let lock = 0;

//METHODS
export default function Deposit(props) {
  const [remainingTime, setRemainingTime] = useState(timeout);
  const [locksFree, setLocksFree] = useState([]);
  const [locksGrid, setLocksGrid] = useState([]);
  const [selectedLocker, setSelectedLocker] = useState();
  const [page, setPage] = useState(1);

  function refreshLockGrid() {
    try {
      let tmpLockers = [];
      for (let row = 1; row <= props.grid.rows; row++) {
        let tmpRow = [];
        for (let col = 1; col <= props.grid.columns; col++) {
          let tmpLock = props.locks.find(lock => lock.grid.page === page && lock.grid.row === row && lock.grid.column === col);
          if (tmpLock?.status === 'free') {
            tmpLock.highLight = true;
            tmpRow.push(tmpLock);
          }
          else if (tmpLock) {
            tmpRow.push(tmpLock);
          }
          else
            tmpRow.push({ status: 'hidden' });
        }
        tmpLockers.push(tmpRow);
      }
      setLocksGrid(tmpLockers);
    }
    catch (e) {
      console.log("Locker Grid is not set propoerly." + e)
    }
  }

  useEffect(() => {
    let tmpFreeLocks = props.locks.filter(lock => lock.status === 'free');
    setLocksFree(tmpFreeLocks);
    if (tmpFreeLocks[lock]) {
      props.selectedLock(tmpFreeLocks[lock]);
      setSelectedLocker({ num: -1 });
      refreshLockGrid();
    }

    const timer = setInterval(() => {
      time--;

      setRemainingTime(time);

      if (time === 0) {
        props.prevState();
        clearInterval(timer);
      }
    }, 1000);
    return () => { time = timeout; clearInterval(timer); }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refreshLockGrid();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleNextPage() {
    if (page === props.grid.pages)
      setPage(1);
    else
      setPage(page + 1);
  }

  function handlePrevPage() {
    if (page === 1)
      setPage(props.grid.pages);
    else
      setPage(page - 1);
  }

  function clickedLocker(lock) {
    if (selectedLocker.num === lock.num) {
      props.selectedLock({ num: -1 });
      setSelectedLocker({ num: -1 });
    }
    else if (lock.status === 'free') {
      props.selectedLock(lock);
      setSelectedLocker(lock);
    }
  }

  if ((locksFree.length <= 0) || (locksGrid.length === 0)) {
    return (
      <div className="widget-menu">
        <div></div>

        <div className="center-widget">
          <Typography variant="h1" color="inherit" display="inline" align="center" className="info-widget">
            {props.texts.messages.no_free_lockers}
          </Typography>

          <Typography variant="subtitle1" color="inherit" display="inline" align="center">
            {props.texts.messages.window_will_close.replace("$time", remainingTime)}
          </Typography>
        </div>

        <div></div>

        <BottomMenu prevState={props.prevState} hideNextButton={true} texts={props.texts} />

      </div>)
  }
  else if ((locksFree.length > 0) && (locksGrid.length > 0)) return (
    <div className="widget-menu">
      <div></div>

      <div>
        <div />

        <div className="door-selector">
          {props.grid.pages > 1 && page > 1 &&
            <IconButton disableRipple className="no-focus-button" style={styles.buttonArrow} onClick={handlePrevPage}>
              <KeyboardArrowLeftIcon style={{ fontSize: "20vmin" }} />
            </IconButton>}

          {/*<Button className="no-focus-button" style={styles.buttonBig} disableRipple variant="outlined" color="primary">{locksFree[lock].name}</Button>*/}
          <LockersGrid locksGrid={locksGrid} clickedLocker={clickedLocker} selectedLocker={selectedLocker} page={page} grid={props.grid} />

          {props.grid.pages > 1 && page < props.grid.pages &&
            <IconButton disableRipple className="no-focus-button" style={styles.buttonArrow} onClick={handleNextPage}>
              <KeyboardArrowRightIcon style={{ fontSize: "20vmin" }} />
            </IconButton>}
        </div>

        <Typography variant="subtitle1" color="inherit" display="inline" align="center">
          {props.texts.messages.window_will_close.replace("$time", remainingTime)}
        </Typography>

      </div>

      <BottomMenu prevState={props.prevState} nextState={props.newDeposit} nextText={props.texts.buttons.deposit.toUpperCase()} showNextButton={(locksFree.length > 0)} disableNextButton={selectedLocker.num === -1} texts={props.texts} />
    </div>
  );
  else if ((time >= timeout) && ((locksFree.length <= 0) || (locksGrid.length === 0))) return (
    <div className="widget-menu">

      <div></div>

      <div className="center-widget">
        <CircularProgress />
      </div>

      <div></div>

    </div>);
};