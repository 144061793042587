import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import ViewCarouselIcon from '@material-ui/icons/ViewCarousel';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import StepConnector from '@material-ui/core/StepConnector';

var styles = require('../styles.js');

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: '5vmin',
  },
  active: {
    '& $line': {
      backgroundColor: 'rgb(16,158,154)'
    },
  },
  completed: {
    '& $line': {
      backgroundColor: 'rgb(0,2,58)'
    },
  },
  line: {
    height: '0.5vmin',
    border: 0,
    backgroundColor: '#ddd',
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: '#ddd',
    zIndex: 1,
    color: '#fff',
    width: '10vmin',
    height: '10vmin',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',    
  }, 
  active: {
    backgroundColor:  'rgb(16,158,154)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  completed: {
    backgroundColor:  'rgb(0,2,58)',
  },
});

function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons = {
    1: <LockOpenIcon style={{ fontSize: "5vmin" }}/>,
    2: <ViewCarouselIcon style={{ fontSize: "5vmin" }}/>,
    3: <ThumbUpIcon style={{ fontSize: "5vmin" }}/>,
  };

  return (
    <div
      className={clsx(classes.root, {        
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}

const MyStepLabel = withStyles(styles.StepLabel)(StepLabel);

export default class HorizontalLinearStepper extends React.Component {

  render() {  
  return (            
    <Stepper alternativeLabel activeStep={this.props.getActiveStep} connector={<ColorlibConnector />}>
      {this.props.texts.steps.map((label) => (
        <Step key={label}>
          <MyStepLabel StepIconComponent={ColorlibStepIcon}>{label}</MyStepLabel>
        </Step>
      ))}
    </Stepper>
  );
  }
}