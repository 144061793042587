//METHODS
export async function getNextFlowState(redirect, key) {
    let result = { path: "/Home", step: -1 };

    switch (redirect) {
        case "/Pin":
        case "/ScanRFID":
        case "/ScanQR":
            {
                if ((key.status === "Deposit") || (key.status === "New")) {
                    result.path = "/" + key.status;
                    result.step = 1;
                }
                else if (key.status === "Adm") {
                    result.path = "/" + key.status;
                    result.step = -1;
                }
                else if (key.status === "Clean") {
                    result.path = "/" + key.status;
                    result.step = -1;
                }
                else {
                    result.path = "/KeyNotValid";
                    result.step = -1;
                }
                break;
            }
        case "/Deposit":
        case "/New":
        case "/Clean":
            result.path = "/Thanks";
            result.step = 3;
            break;
        case "/Thanks":
            result.path = "/Home";
            result.step = -1;
            break;
        case "/Users":
            if ((key.status !== "Invalid") && (key.status !== "New"))
                result.path = "/AddKey";
            else
                result.path = "/Adm";
            break;
        case "/Reset":
        case "/Contact":
        case "/AddKey":
            result.path = "/Adm";
            result.step = -1;
            break;
        case "/Open":
            result.path = "/Open";
            result.step = 0;
            break;
        default:
            break;
    }

    return result;
}
export function getPrevFlowState(redirect) {
    let result = { path: "/Home", step: -1 };

    switch (redirect) {
        case "/Adm":
        case "/Pin":
        case "/ScanRFID":
        case "/ScanQR":
        case "/Deposit":
        case "/Clean":
            result.path = "/Home";
            result.step = -1;
            break;
        case "/Open":
        case "/Reset":
        case "/Users":
        case "/AddKey":
        case "/Contact":
            result.path = "/Adm";
            result.step = -1;
            break;
        default:
            break;
    }

    return result;
}