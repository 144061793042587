//GLOBALS
let station_id = '';
let db = '';

//METHODS
export async function init(firestore, id, callbackDB) {    
    db = firestore;
    station_id = id;

    try {
        var query = await db.collection('stations').doc(station_id);
        query.onSnapshot(function (snapshot) {
            callbackDB(snapshot.data());
        });
    }
    catch (e) { console.log("error: " + e); }
}

export async function getStation(id) {
    try {
        var docRef = await db.collection("stations").doc(id);
        var res = await docRef.get();
        return await res.data();
    }
    catch (e) {
        console.log("<getStation> Error: " + e);
    }
}

export async function checkStation(db, id) {
    try {
        var docRef = await db.collection("stations").doc(id);
        var res = await docRef.get();
        return await res.data();
    }
    catch (e) {
        console.log("<checkStation> Error: " + e);
    }
}