//UTILS
import { React } from 'react';

//UI
import '../App.css';
import Typography from '@material-ui/core/Typography';
import BottomMenu from '../components/BottomMenu';

//CUSTOM REQUIRES
var styles = require('../styles.js');

//METHODS
export default function Contact(props) {

  return (
    <div className="widget-menu">
      <div></div>

      <div className="center-widget">
        <Typography variant="h1" color="inherit" display="inline" align="center" className="info-widget">
          support@mylock.es
        </Typography>
      </div>

      <div></div>

      <BottomMenu prevState={props.prevState} hideNextButton={true} texts={props.texts} />

    </div>
  );
}
