//UTILS
import { React } from 'react';

//UI
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

//ICONS https://react-icons.github.io/react-icons
import { ImQrcode } from "react-icons/im";
import { GiKeyCard } from "react-icons/gi";
import { MdKeyboardHide } from "react-icons/md";

//PAGES

//CUSTOM STYLES
var styles = require('../styles.js');
const ButtonBasic = withStyles(styles.ButtonBasic)(Button);

//GLOBALS

//METHODS

//GLOBALS

//METHODS
export default function Home(props) {  
  
  return (  
    <div>
      {!props.ui.MANT &&      
      <div>          
        {props.ui.QR && <div className="button-menu">
          <ButtonBasic                          
              endIcon={<ImQrcode style={{ fontSize: "6vmin" }}/>}
              onClick={() => props.goFlow("/ScanQR")}              
            >
              {props.texts.buttons.qr_code.toUpperCase()}
          </ButtonBasic>
        </div>}

        {props.ui.RF && <div className="button-menu">
          <ButtonBasic              
              endIcon={<GiKeyCard style={{ fontSize: "8vmin" }}/>}
              onClick={() => props.goFlow("/ScanRFID")}
            >
              {props.texts.buttons.members.toUpperCase()}
          </ButtonBasic>
        </div>}

        {props.ui.PIN && <div className="button-menu">
          <ButtonBasic        
              
              endIcon={<MdKeyboardHide style={{ fontSize: "7vmin" }}/>}                        
              onClick={() => props.goFlow("/Pin")}
            >
              {props.texts.buttons.pin_code.toUpperCase()}
          </ButtonBasic>
        </div>}
      </div>}                          
    </div>);
};