import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function InputAdornments(props) {
  const [showPin, setShowPin] = React.useState(false);

  const handleClickShowPin = () => {
    setShowPin(!showPin)
  };

  return (
    <FormControl style={{width: '100%'}}>
      <TextField
        id="outlined-error-helper-text"
        label={props.texts.messages.scan_pin_code}
        variant="outlined"
        InputProps={{
          readOnly: true,
          inputMode: 'numeric',
          style: { fontSize: '7vmin' },
          endAdornment: <InputAdornment position="end">
            <IconButton
              aria-label="toggle pin visibility"
              onClick={handleClickShowPin}
            >
              {showPin ? <Visibility style={{fontSize: '5vmin'}} /> : <VisibilityOff style={{fontSize: '5vmin'}} />}
            </IconButton>
          </InputAdornment>
        }}
        InputLabelProps={{
          style: { fontSize: '3vmin' }
        }}
        value={props.label}
        type={showPin ? 'text' : 'password'}
      />
    </FormControl>
  );
}
