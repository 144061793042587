//UTILS
import { React, useEffect, useState } from 'react';

//UI
import '../App.css';
import Typography from '@material-ui/core/Typography';
import closeDoor from '../images/close_door.png';


//CUSTOM REQUIRES
var styles = require('../styles.js');

//METHODS
export default function Thanks(props) {
  const [seconds, setSeconds] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0)
        setSeconds(seconds => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  if (seconds === 0)
    props.goHome();

  return (
    <div className="widget-menu">

      <div></div>

      <div className="center-widget" onClick={() => props.goHome()}>

        <Typography variant="h1" color="inherit" display="inline" align="center" className="info-widget">
          {props.texts.messages.thanks}
        </Typography>

        <img src={closeDoor} alt="closeDoor" style={{ marginTop: '5vmin' }} />
        <Typography variant="subtitle1" color="inherit" display="inline" align="center" className="info-widget">
          {props.texts.messages.keep_door_closed}
        </Typography>

      </div>

      <div></div>

    </div>
  );
}
