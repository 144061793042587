//UTILS
import React, { useState, useEffect } from 'react';

//UI
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';
import PeopleIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Typography from '@material-ui/core/Typography';

//PAGES

//CUSTOM STYLES
var styles = require('../styles.js');

//GLOBALS
const ButtonAdmin = withStyles(styles.ButtonAdmin)(Button);
const ButtonBasic = withStyles(styles.ButtonBasic)(Button);
var timer;

//METHODS
export default function Adm(props) {
  const [remainingTime, setRemainingTime] = useState();

  useEffect(() => {
    let pendingTime = 60;
    setRemainingTime(pendingTime);
    timer = setInterval(async () => {
      setRemainingTime(pendingTime--);
      if (pendingTime < 0) {
        handlePrevState();
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePrevState() {
    clearInterval(timer);
    props.prevState();
  }

  return (
    <div>
      <div className="button-menu-adm">
        <ButtonAdmin
          endIcon={<LockOpenIcon style={{ fontSize: "6vmin" }} />}
          disabled={!props.ui.ADMIN.OPEN}
          onClick={() => props.goFlow("/Open")}
        >
          {props.texts.buttons.open.toUpperCase()}
        </ButtonAdmin>
      </div>

      <div className="button-menu-adm">
        <ButtonAdmin
          endIcon={<NoEncryptionIcon style={{ fontSize: "6vmin" }} />}
          disabled={!props.ui.ADMIN.RESET}
          onClick={() => props.goFlow("/Reset")}
        >
          {props.texts.buttons.delete_deposit.toUpperCase()}
        </ButtonAdmin>
      </div>

      <div className="button-menu-adm">
        <ButtonAdmin
          endIcon={<PeopleIcon style={{ fontSize: "6vmin" }} />}
          disabled={!props.ui.ADMIN.USERS}
          onClick={() => props.goFlow("/Users")}
        >
          {props.texts.buttons.add_key.toUpperCase()}
        </ButtonAdmin>
      </div>

      <div className="button-menu-adm">
        <ButtonAdmin
          disabled={!props.ui.ADMIN.REBOOT}
          onClick={() => props.reboot()}
          style={props.ui.ADMIN.REBOOT ? { backgroundImage: "linear-gradient( 20deg, rgb(216,158,154) 0%, rgb(200,2,58) 100%)" } : {}}
        >
          {props.texts.buttons.reboot.toUpperCase()}
        </ButtonAdmin>
      </div>

      <div className="button-menu-adm">
        <ButtonBasic
          style={{ background: " rgb(0,2,58)" }}
          endIcon={<ExitToAppIcon style={{ fontSize: "6vmin" }} />}
          onClick={() => props.prevState()}
        >
          {props.texts.buttons.exit.toUpperCase()}
        </ButtonBasic>
      </div>

      <Typography variant="subtitle1" color="inherit" display="inline" align="center">
        {props.texts.messages.window_will_close.replace("$time", remainingTime)}
      </Typography>

    </div>);
};