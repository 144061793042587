//UTILS
import { React, useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { get, set } from 'idb-keyval';
import { firestore, functions } from "./firebase";
import { clearCache } from "clear-cache"
import { isEqual } from 'lodash';

//UI
import './App.css';
import Stepper from './components/Stepper';
import mainLogo from './images/main-logo-2.png';
import Typography from '@material-ui/core/Typography';
import { ThemeProvider } from '@material-ui/core/styles';
import QRlogo from './images/QR-download-frame.png';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import 'animate.css';

//PAGES
import StationRegister from './pages/StationRegister';
import Adm from './pages/Adm';
import Open from './pages/Open';
import Reset from './pages/Reset';
import Users from './pages/Users';
import AddKey from './pages/AddKey';
import Contact from './pages/Contact';
import Home from './pages/Home';
import Pin from './pages/Pin';
import ScanQR from './pages/ScanQR';
import ScanRFID from './pages/ScanRFID';
import Deposit from './pages/Deposit';
import Clean from './pages/Clean';
import Collect from './pages/Collect';
import Thanks from './pages/Thanks';
import KeyNotValid from './pages/KeyNotValid';

//CUSTOM TOOLS
const allTexts = require('./texts.json');
var dbm = require('./tools/DBManager.js');
var lockServer = require('./tools/LockServer.js');
var flowManager = require('./tools/FlowManager.js');
var styles = require('./styles.js');

//GLOBALS
const versionNum = process.env.REACT_APP_VERSION;
const defaultKey = { id: "", status: "", deposits: 0, time: 0 };
const defaultLock = { num: "", status: "", isOpen: false, time: 0, KEY: defaultKey };
const defaulUI = { MANT: true };
const defaultStation = { LOCKS: defaultLock, KEYS: defaultKey, UI: defaulUI, time: new Date().getTime() };
let station = defaultStation;
let registered = '';
let apiKey = '';
function Alert(props) { return <MuiAlert elevation={6} variant="filled" {...props} />; }

export default function App() {
  //ATTRIBUTES
  const [online, setOnline] = useState(navigator.onLine ? "" : "⚠");
  const [texts, setTexts] = useState(allTexts.es);
  const [versionId, setVersionId] = useState("");
  const [redirect, setRedirect] = useState("/Home");
  const [clockState, setClockState] = useState();
  const [activeStep, setActiveStep] = useState(-1);
  const [lock, setLock] = useState(defaultLock);
  const [key, setKey] = useState(defaultKey);
  const [DBOK, setDBOK] = useState(false);
  const [homeUI, setHomeUI] = useState({ QR: false, RF: false, PIN: false, MANT: true, ADMIN: { OPEN: false, RESET: false, CONTACT: true } });
  const [snackBar, setSnackBar] = useState({ open: false, message: "", severity: "info" });

  window.addEventListener('offline', (event) => { });
  onoffline = (event) => { setOnline("⚠") };

  window.addEventListener('online', (event) => { });
  ononline = (event) => { setOnline("") };

  function isOnline() {
    return navigator.onLine;
  }

  function setStation(station_) {
    handleSetLanguage(station_?.language ?? 'es');
    station = station_;
  }

  function handleSetLanguage(language) {
    switch (language) {
      case 'ca':
        setTexts(allTexts.ca);
        break;
      case 'en':
        setTexts(allTexts.en);
        break;
      case 'es':
      default:
        setTexts(allTexts.es);
    }
  }

  async function cloudLog(log, symbol, type, info) {
    if (isOnline()) {
      let data = { stationId: registered, log, symbol, type, info, time: new Date().getTime(), apiKey };
      try {
        let response = await functions.httpsCallable('log')(data);
        if (response.data.code === 400)
          console.log(symbol + " " + log + "\n<cloudLog> DATA:" + JSON.stringify(data) + " RESPONSE:" + JSON.stringify(response));
        else
          console.log(symbol + " " + log + "\n<cloudLog> ERROR: " + JSON.stringify(response));
      }
      catch (e) {
        console.log(symbol + " " + log + "\n<cloudLog> ERROR: " + e);
      }
    }
  }

  async function cloudSetStation(station) {
    if (isOnline()) {
      try {
        await functions.httpsCallable('setStation')({ station, stationId: registered, apiKey });
        //await cloudLog("UPDATING FIRESTORE", "⬆️", "UPLOADING", "Updating FIRESTORE from local DataBase.");
      }
      catch (e) {
        console.log("CLOUD <cloudSetStation> ERROR: " + e);
      }
    }
  }

  async function cloudSetStationLocks(locks, time) {
    if (isOnline()) {
      try {
        await functions.httpsCallable('setStationLocks')({ locks, time, stationId: registered, apiKey });
      }
      catch (e) {
        console.log("CLOUD <cloudSetStationLocks> ERROR: " + e);
      }
    }
  }

  function snackBarComponent() {
    return (<Snackbar open={snackBar.open} autoHideDuration={5000} onClose={() => setSnackBar({ open: false, message: snackBar.message, severity: snackBar.severity })} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Alert icon={false} style={{ fontSize: '40px' }} onClose={() => setSnackBar({ open: false, message: snackBar.message, severity: snackBar.severity })} severity={snackBar.severity}>
        {snackBar.message}
      </Alert>
    </Snackbar>)
  }

  async function updateStation(data, station, forced = false) {
    if (!forced) {
      //Data to prevent updating
      delete data.LOCKS;
    }

    if (data.time >= station.time) {
      setStation(data);
      await lockServer.updateStation(data);
    }
  }

  async function handleGoFlow(page) {
    setActiveStep(0);
    setRedirect(page);

    try {
      refreshStation();
    }
    catch (e) {
      handlePrevFlowState();
      console.log("ERROR: <App> handleGoFlow>:" + e);
    }
  }

  async function handleNextFlowState() {
    let goTo = await flowManager.getNextFlowState(redirect, key);
    setRedirect(goTo.path);
    setActiveStep(goTo.step);
  }

  function handlePrevFlowState() {
    let goTo = flowManager.getPrevFlowState(redirect);
    setRedirect(goTo.path);
    setActiveStep(goTo.step);
  }

  async function handleSetKey(keyCode) {
    let tmp_key;

    if (keyCode !== "") {
      let tmp_lock = station.LOCKS.find(f_lock =>
        (((keyCode === f_lock.KEY.id) && (keyCode.length === f_lock.KEY.id.length)) ||
          ((keyCode === f_lock.KEY.pin) && (keyCode.length === f_lock.KEY.pin.length))) && (keyCode.length > 0));

      if (tmp_lock && tmp_lock.status !== "free") {
        if (tmp_lock.status === "in_use" || tmp_lock.status === "dirty") {
          cloudLog("KEY READ", "📲", "INFO", "Collect key detected Key [" + tmp_lock.KEY.name + "]");
          tmp_lock.KEY.status = 'New';
          setLock(tmp_lock);
          tmp_key = tmp_lock.KEY;
        }
        else if (tmp_lock.status === "block") {
          tmp_key = { status: "Blocked", id: keyCode };
          cloudLog("KEY READ", "📲", "ALERT", "Blocked locker collect key detected Key [" + tmp_lock.KEY.name + "]");
        }
        else {
          tmp_key = { status: "Invalid", id: keyCode };
          cloudLog("KEY READ", "📵", "INFO", "Invalid collect key detected Key [" + tmp_lock.KEY.name + "]");
        }
      }
      else {
        if (await lockServer.isAdmin(keyCode)) {
          tmp_key = { status: "Adm", id: keyCode };
          cloudLog("KEY READ", "👁‍🗨", "INFO", "Admin key detected Key [" + keyCode + "]");
        }
        else {
          tmp_key = await validateKEY(keyCode);

          if (['superadmin', 'admin', 'manager'].includes(tmp_key?.role)) {
            tmp_key = { status: "Adm", id: keyCode, userId: tmp_key.userId };
            cloudLog("KEY READ", "👁‍🗨", "INFO", "Admin key detected Key [" + keyCode + "]");
          }
          else if (['staff'].includes(tmp_key?.role)) {
            tmp_key = { status: "Clean", id: keyCode, userId: tmp_key.userId };
            cloudLog("KEY READ", "👁‍🗨", "INFO", "Staff key detected Key [" + keyCode + "]");
          }
          else if (['New', 'Reset'].includes(tmp_key?.status)) {
            tmp_key.deposits++;
            tmp_key.status = "Deposit";
            cloudLog("KEY READ", "📲", "INFO", "Deposit key detected Key [" + tmp_key?.name + "]");
          }
          else if (tmp_key?.status === 'Block') {
            tmp_key = { status: "Blocked", id: keyCode };
            cloudLog("KEY READ", "📲", "ALERT", "Blocked locker key detected Key [" + keyCode + "]");
          }
          else {
            tmp_key = { status: "Invalid", id: keyCode };
            cloudLog("KEY READ", "📵", "INFO", "Invalid key detected Key [" + keyCode + "]");
          }
        }
      }
    }
    else
      tmp_key = { status: "Invalid", id: keyCode };

    setKey(tmp_key);
    return tmp_key;
  }

  async function addKey(keyId) {
    let response = (await functions.httpsCallable('update_users')({ users: [{ id: key.userId, keyCode: keyId }], stationId: station.id, apiKey }))?.data;

    if (response?.usersUpdated?.length > 0) {
      cloudLog("ADD KEY", "➕", "ADMIN", "Added KEY [" + keyId + "] to user " + key.userId);
      return { userUpdated: response?.usersUpdated?.[0] };
    }
    else if (response?.usersError?.length > 0) {
      cloudLog("ERROR", "➕", "ADMIN", "Error adding KEY [" + keyId + "] to user " + key.userId + " due to " + response?.usersError?.[0]?.error);
      return { userError: response?.usersError?.[0] };
    }
  }

  async function setQuery(query, data) {
    try {
      return await functions.httpsCallable(query)(data);
    }
    catch (e) {
      console.log("ERROR: <setQuery> failed error: " + e);
      const time = new Date().getTime();
      station.pendingQueries ? station.pendingQueries.push({ query, data, time }) : station.pendingQueries = [{ query, data, time }];
      setStation(station);
      await lockServer.setStation(station);
    }
  }

  function handleNoFreeLocks() {
    cloudLog("NO FREE LOCKS", "🚫", "INFO", "Station has no free locks.")
  }

  async function handleNewDeposit() {
    handleNextFlowState();

    //UPDATE LOCK
    let tmp_lock = station.LOCKS.find(f_lock => (lock.num === f_lock.num));
    tmp_lock.deposits++;
    let now = new Date().getTime();
    tmp_lock.time = now;
    tmp_lock.KEY = { id: key.id, pin: key.pin, name: key.name, isRemote: key.isRemote, time: now };
    tmp_lock.status = 'in_use';
    delete tmp_lock.highLight;
    lockServer.setLock(tmp_lock);

    //OPEN LOCK
    await lockServer.openLock({ num: lock.num });
    lockServer.setTime(now);

    //UPDATE STATION
    lockServer.setTime(now);
    station.time = now;
    setStation(station);

    //UPDATE CLOUD    
    await setQuery('deposit', { key, stationId: registered, apiKey, station: { address: station.address, imageUrl: station.imageUrl, name: station.name } });

    cloudLog("DEPOSIT", "🔒", "USE", "New deposit Lock [" + tmp_lock.name + "] Key/User [" + key.name + "]");

    await cloudSetStation(station);
  }

  async function handleNewClean() {
    handleNextFlowState();

    //UPDATE LOCK
    let tmp_lock = station.LOCKS.find(f_lock => (lock.num === f_lock.num));
    let now = new Date().getTime();
    tmp_lock.time = now;
    tmp_lock.status = 'free';
    delete tmp_lock.highLight;
    lockServer.setLock(tmp_lock);

    //OPEN LOCK
    await lockServer.openLock({ num: lock.num });
    lockServer.setTime(now);

    //UPDATE STATION
    lockServer.setTime(now);
    station.time = now;
    setStation(station);

    //UPDATE CLOUD    
    //await setQuery('deposit', { key, stationId: registered, apiKey, station: { address: station.address, imageUrl: station.imageUrl, name: station.name } });

    cloudLog("CLEAN", "🧼", "CLEAN", "Cleaning Lock [" + tmp_lock.name + "] Key/User [" + (key.userId ?? key.name ?? key.id) + "]");

    await cloudSetStation(station);
  }

  async function handleNewCollect(isDirty = false) {
    handleNextFlowState();
    let now = new Date().getTime();

    //UPDATE LOCK
    let tmp_lock = station.LOCKS.find(f_lock => (lock.num === f_lock.num));
    tmp_lock.time = now;
    tmp_lock.KEY = { id: "", name: "", isRemote: "", pin: "" };
    tmp_lock.status = isDirty === true ? 'dirty' : 'free';
    lockServer.setLock(tmp_lock);

    //OPEN LOCK
    await lockServer.openLock({ num: lock.num });
    lockServer.setTime(now);

    //UPDATE STATION
    station.avgTime = (station.avgTime ?? 0 + (now - lock.time)) / 2;
    station.time = now;
    setStation(station);

    //UPDATE CLOUD
    await setQuery('collect', { key, stationId: registered, apiKey });

    cloudLog("COLLECT", "🔓", "USE", "New collect Lock [" + tmp_lock.name + "] Key/User [" + key.name + "]");

    await cloudSetStation(station);
  }

  async function handleOpenLock(isAdmin = false) {
    try {
      handleNextFlowState();

      console.log("isAdmin:", isAdmin)

      if (isAdmin === true)
        cloudLog("OPEN", "👁‍🗨", "OPEN", "Admin " + (key.name ?? key.id) + " is opening Lock " + lock.name);
      else
        cloudLog("OPEN", "👁‍🗨", "OPEN & KEEP", "User " + (key.name ?? key.id) + " is opening Lock " + lock.name);

      await lockServer.openLock({ num: lock.num });
      lockServer.setTime(new Date().getTime());
    }
    catch (e) {
      cloudLog("OPEN", "⚠️", "ERROR", "Error opening Lock [" + lock.name + "] Key/User [" + key.id + "]. " + e);
    }
  }

  async function handleResetLock() {
    try {
      const tmpLock = JSON.parse(JSON.stringify(lock));
      //let tmp_keycode = lock.KEY.id;
      //handleNextFlowState();
      let now = new Date().getTime();
      lock.time = now;
      lock.isOpen = true;
      lock.KEY = { id: "", name: "" };
      lock.status = 'free';
      await lockServer.setLock(lock);
      lockServer.setTime(now);
      station.LOCKS[station.LOCKS.findIndex(x => x.num === lock.num)] = lock;
      station.time = now;

      //UPDATE KEY
      //let tmp_key = station.KEYS ? station.KEYS.find(f_key => (tmp_keycode === f_key.id)) : undefined;
      //tmp_key.status = "Reset";
      //tmp_key.lock = "";
      //lockServer.addKey(tmp_key);

      await functions.httpsCallable('resetLock')({ stationId: station.id, lock: tmpLock, apiKey })
        .catch(e => console.log("resetLock ERROR:" + e))

      await cloudSetStationLocks(station.LOCKS, now);
      cloudLog("RESET", "👁‍🗨", "ADMIN", "Reseting Lock [" + lock.name + "] Key/User [" + key.id + "]");
    }
    catch (e) {
      cloudLog("RESET", "⚠️", "ERROR", "Error reseting Lock [" + lock.name + "] Key/User [" + key.id + "]. " + e);
    }
  }

  async function refreshStation() {
    try {
      let tmp_station = await lockServer.getStation();

      if (tmp_station?.pendingQueries?.length > 0) {
        // Execute all pending queries
        let tmp_pendingQueries = [];
        for (let q in tmp_station.pendingQueries) {
          let tmpQuery = tmp_station.pendingQueries[q];
          try {
            let response = await functions.httpsCallable(tmpQuery.query)(tmpQuery.data);
            if (response.data.code === 200)
              cloudLog("QUERY", "⌛", "QUERY", "Running query: " + tmpQuery.query + " from " + new Date(tmpQuery.time).toLocaleString());
            else
              tmp_pendingQueries.push(tmpQuery);

            response = null;
          }
          catch (e) {
            tmp_pendingQueries.push(tmpQuery);
            console.log("ERROR: <httpsCallable> failed error: " + e);
          }
          tmpQuery = null;
        }
        tmp_station.pendingQueries = tmp_pendingQueries;
        await lockServer.setStation(tmp_station);
      }

      if (!tmp_station) {
        if (!homeUI.MANT) cloudLog("ALERT", "💤", "MAINTENANCE", "Station under maintenance!");
        homeUI.MANT = true;
        setHomeUI(homeUI);
        throw new Error("Going maintenance mode due to lockServer not responding.");
      }
      else if (!isEqual(tmp_station, station)) {
        setStation(tmp_station);
        setHomeUI(tmp_station.UI);
        await cloudSetStation(tmp_station);
      }
      else if (tmp_station?.UI) {
        setStation(tmp_station);
        setHomeUI(tmp_station.UI);
      }

      tmp_station = null;
    }
    catch (e) {
      console.log("ERROR: <refreshStation>:" + e);
    }
  }

  async function handleCheckStation(id) {
    setDBOK(await dbm.checkStation(firestore, id));
  }

  async function validateKEY(keyCode) {
    let tmp_key = station.KEYS ? station.KEYS.find(f_key =>
      ((keyCode === f_key.id) ||
        (keyCode === f_key.pin)) &&
      (keyCode.length > 0)
    ) : undefined;

    //KEY IS IN LOCALDB
    if (tmp_key && tmp_key.status !== 'Expired') {
      if (tmp_key.isRemote) {
        let user;
        if (isOnline()) {
          try {
            user = (await functions.httpsCallable('keyUser')({ keyCode, stationId: registered, apiKey })).data.user;
          }
          catch (e) {
            console.log("ERROR: <validateKEY> Firebase /keyUser failed error: " + e);
          }
        }

        if (user && user.status.state !== 'New' && user.status.state !== 'Reset')
          return;
      }

      //tmp_key.id = keyCode;
      //SET START TIME
      if (!tmp_key.time)
        tmp_key.time = new Date().getTime();

      if ((tmp_key.time && tmp_key.time_limit > 0 && (parseInt((new Date() - tmp_key.time) / 60000) > tmp_key.time_limit)) ||
        (tmp_key.deposits_limit > 0 && tmp_key.deposits >= tmp_key.deposits_limit)) {
        //KEY EXPIRED!
        tmp_key.status = "Expired";
      }

      return tmp_key;
    }
    //KEY IS NOT IN LOCALDB >> CHECK CLOUD
    else {
      let user;
      if (isOnline()) {
        try {
          user = (await functions.httpsCallable('keyUser')({ keyCode, stationId: registered, apiKey })).data.user;
        }
        catch (e) {
          console.log("ERROR: <validateKEY> Firebase /keyUser failed error: " + e);
        }
      }

      if (user && (user.status.state === 'New' || user.status.state === 'Reset')) {
        tmp_key = {
          isRemote: true,
          deposits: 0,
          status: "New",
          time: new Date().getTime(),
          id: user.keyCode,
          pin: user.pin,
          name: user.email,
          role: user.role,
          userId: user.id
        };

        //await lockServer.addKey(tmp_key);
        //station.KEYS.push(tmp_key);
        //setStation(station);

        return tmp_key;
      }
    }

    return;
  }

  async function dbchange(data) {
    try {
      let tmp_station = await lockServer.getStation();

      if (data?.action === "clear") {
        lockServer.setStation(tmp_station);
        await cloudSetStation(tmp_station);
        await cloudLog("CLEAR CACHE", "🆑", "CLEAR", "Clearing cache and restarting station app.");
        clearCache();
      }
      else if (data?.action !== "" && data?.action !== tmp_station?.action) {
        tmp_station.action = "Ok";

        switch (data.action) {
          case 'sync':
            await cloudLog("SYNC", "⬆️", "SYNC", "Sync Cloud with local DB data time " + tmp_station.time + ".");
            await cloudSetStation(tmp_station);
            break;
          case 'update':
            if (tmp_station) {
              data.action = tmp_station.action;
              data.time = tmp_station.time;
              updateStation(data, tmp_station);
              await cloudLog("UPDATE", "⬇️", "UPDATE", "Updating Local DB.");
              await cloudSetStation(data);
            }
            break;
          case 'force-update':
            if (tmp_station) {
              data.action = tmp_station.action;
              data.time = tmp_station.time;
              updateStation(data, tmp_station, true);
              await cloudLog("FORCE UPDATE", "⏬", "UPDATE", "Forced Updating ALL data to Local DB.");
              await cloudSetStation(data);
            }
            break;
          case 'maintenance':
            if (tmp_station && tmp_station.UI.MANT === false) {
              tmp_station.UI.MANT = true;
              setStation(tmp_station);
              await lockServer.setStation(tmp_station);
              await cloudLog("CLOUD", "💤", "MAINTENANCE", "Going maintenance mode.");
              await cloudSetStation(tmp_station);
            }
            break;
          case 'idle':
            if (tmp_station && tmp_station.UI.MANT === true) {
              tmp_station.UI.MANT = false;
              setStation(tmp_station);
              await lockServer.setStation(tmp_station);
              await cloudLog("CLOUD", "👌", "IDLE", "Going idle mode.");
              await cloudSetStation(tmp_station);
            }
            break;
          case 'run':
            if (data.params.run) {
              tmp_station.params = {};
              lockServer.setStation(tmp_station);
              await cloudLog("RUN", "🏃", "ALERT", "Running remote command: " + data.params.run);
              lockServer.run(data.params.run);
            }
            else
              tmp_station.action = "No-Ok";

            await cloudSetStation(tmp_station);
            break;
          case 'reboot':
            lockServer.setStation(tmp_station);
            await cloudSetStation(tmp_station);
            await cloudLog("REBOOT", "🔄", "ALERT", "Remote reboot.");
            lockServer.reboot();
            break;
          case 'open':
            if (data.params.locks?.length > 0 && data.params.user) {
              data.params.locks.forEach(async (lock) => {
                if (lock.name && data.params.user && parseInt(lock.num) > 100) {
                  cloudLog("OPEN", "👁‍🗨", "REMOTE", "Remote opening Lock [" + lock.name + "] by " + data.params.user);
                  await lockServer.openLock({ num: lock.num });
                }
              });
              tmp_station.params = {};
              lockServer.setTime(new Date().getTime());
            }
            else
              tmp_station.action = "No-Ok";

            await cloudSetStation(tmp_station);
            break;
          case 'reset':
            if (data.params.locks?.length > 0 && data.params.user) {
              data.params.locks.forEach(async (lock) => {
                if (lock.name && data.params.user && parseInt(lock.num) > 100) {
                  cloudLog("RESET", "👁‍🗨", "REMOTE", "Remote reseting Lock [" + lock.name + "] by " + data.params.user);
                  lock.time = new Date().getTime();
                  lock.KEY = { id: "", name: "", pin: "" };
                  lock.status = "free";
                  await lockServer.setLock(lock);
                }
              });
              tmp_station.params = {};
              lockServer.setTime(new Date().getTime());
            }
            else
              tmp_station.action = "No-Ok";

            await cloudSetStation(tmp_station);
            break;
          case 'set-lock':
            if (data.params.locks?.length > 0 && data.params.user) {
              data.params.locks.forEach(async (lock) => {
                if (lock.name && data.params.state && data.params.user && parseInt(lock.num) > 100) {
                  cloudLog("SET", "👁‍🗨", "REMOTE", "Remote seting Lock to " + data.params.state + " [" + lock.name + "] by " + data.params.user);
                  lock.time = new Date().getTime();
                  if (data.params.key) lock.KEY = data.params.key;
                  lock.status = data.params.state;
                  await lockServer.setLock(lock);
                }
              });
              tmp_station.params = {};
              lockServer.setTime(new Date().getTime());
            }
            else
              tmp_station.action = "No-Ok";

            await cloudSetStation(tmp_station);
            break;
          default:
            tmp_station.action = "No-Ok";
            await cloudSetStation(tmp_station);
        }
      }
    }
    catch (e) {
      console.log("ERROR: <dbchange> Firebase failed error: " + e);
    }
  }

  async function handleRegisterStation(id, apiKey) {
    set('station_id', id);
    set('api_key', apiKey);
    await dbm.init(firestore, id, dbchange);
    let tmp_station = await dbm.getStation(id);
    if (tmp_station) await lockServer.setStation(tmp_station);
    registered = id;
    handleGoFlow("/Home");
    await cloudLog("REGISTER", "🆕", "INFO", "New Station Registered! [" + id + "]");
  }

  useEffect(() => {
    if (station) setVersionId(online + " " + station.name + " v" + versionNum);
  }, [station, online]);  // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    console.log("INFO: App init [" + versionNum + "]");
    get('station_id').then(async (val) => {
      if (val) {
        registered = val;
        let tmp_station = await lockServer.getStation();
        const fromAction = tmp_station.action;
        if (tmp_station) {
          tmp_station.time = new Date().getTime();
          tmp_station.sw_version = versionNum;
          tmp_station.action = "Ok";
          await lockServer.setStation(tmp_station);
          await cloudSetStation(tmp_station);
          setStation(tmp_station);
        }
        dbm.init(firestore, val, dbchange);

        get('api_key').then(async (val) => {
          if (val)
            apiKey = val;
          if (fromAction !== "clear") cloudLog("RESTART", "🔃", "RESTART", "Station Reloaded! App init version [" + versionNum + "]");
        });

        //REFRESH STATION     
        const interval = setInterval(() => {
          refreshStation();
        }, 5000);

        return () => clearInterval(interval);
      }
      else
        registered = "unregistered";
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    handleNextFlowState();
  }, [key]); // eslint-disable-line react-hooks/exhaustive-deps

  //CLOCK
  useEffect(() => {
    const interval = setInterval(() => setClockState(new Date().toLocaleTimeString()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  //- - - - - - - - - - -  - - - - - - - - - - - - - - - - -  - - - - - - - - - - - - - - - - -  - - - - - - - - - - - - - - - - -  - - - - - -
  //- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - RENDER APP  - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
  //- - - - - - - - - - -  - - - - - - - - - - - - - - - - -  - - - - - - - - - - - - - - - - -  - - - - - - - - - - - - - - - - -  - - - - - -
  return (
    <Router>
      <ThemeProvider theme={styles.theme}>
        <script defer src="/__/firebase/8.2.6/firebase-app.js"></script>
        <script defer src="/__/firebase/8.2.6/firebase-analytics.js"></script>
        <script defer src="/__/firebase/init.js"></script>
        <script defer src="./init-firebase.js"></script>

        {snackBarComponent()}

        {(registered === "unregistered") &&
          <div className="loading">
            <StationRegister register={handleRegisterStation} check={handleCheckStation} isDBok={DBOK} />
          </div>}

        {registered && (registered !== "unregistered") && station &&
          <div className="App">
            <Stepper getActiveStep={activeStep} texts={texts} />

            <Route path="/Adm">
              <div className="main-menu">
                <div className="logo-menu">
                  <Typography variant="h1" color="inherit" display="inline" align="center" className="info-widget">
                    <b>email: </b>{station?.mail}
                  </Typography>
                  <Typography variant="h1" color="inherit" display="inline" align="center" className="info-widget">
                    <b>phone: </b>{station?.phone}
                  </Typography>
                </div>

                <Adm goFlow={handleGoFlow} prevState={handlePrevFlowState} reboot={() => lockServer.reboot()} ui={homeUI} texts={texts} />
                <div />
              </div>
              <div className="ilock-version">
                <div>{versionId}</div>
                <div>{clockState}</div>
              </div>
            </Route>

            <Route path="/Open">
              <Open openLock={() => handleOpenLock(true)} locks={station.LOCKS} grid={station.grid} selectedLock={setLock} prevState={handlePrevFlowState} noLocks={handleNoFreeLocks} texts={texts} />
            </Route>

            <Route path="/Reset">
              <Reset
                resetLock={handleResetLock}
                locks={station.LOCKS}
                grid={station.grid}
                selectedLock={setLock}
                prevState={handlePrevFlowState}
                noLocks={handleNoFreeLocks}
                setSnackBar={setSnackBar}
                texts={texts}
              />
            </Route>

            <Route path="/Users">
              <Users
                setKey={handleSetKey}
                nextState={handleNextFlowState}
                prevState={handlePrevFlowState}
                setSnackBar={setSnackBar}
                texts={texts}
              />
            </Route>

            <Route path="/AddKey">
              <AddKey
                addKey={addKey}
                prevState={handlePrevFlowState}
                setSnackBar={setSnackBar}
                texts={texts}
              />
            </Route>

            <Route path="/Contact">
              <Contact prevState={handlePrevFlowState} texts={texts} />
            </Route>

            <Route path="/Thanks">
              <Thanks lockID={lock.name} goHome={handleNextFlowState} texts={texts} />
              <div />
            </Route>

            <Route path="/KeyNotValid">
              <KeyNotValid status={key.status} keyCode={handleSetKey} goHome={handleNextFlowState} texts={texts} />
              <div />
            </Route>

            <Route path="/Deposit">
              <Deposit newDeposit={handleNewDeposit} locks={station.LOCKS} grid={station.grid} selectedLock={setLock} prevState={handlePrevFlowState} noLocks={handleNoFreeLocks} texts={texts} />
              <div />
            </Route>

            <Route path="/Clean">
              <Clean newClean={handleNewClean} locks={station.LOCKS} grid={station.grid} selectedLock={setLock} prevState={handlePrevFlowState} noLocks={handleNoFreeLocks} texts={texts} />
              <div />
            </Route>

            <Route path="/New">
              <Collect newCollect={handleNewCollect} lock={lock} openLock={handleOpenLock} prevState={handlePrevFlowState} ui={homeUI} texts={texts} />
            </Route>

            <Route path="/Pin">
              <Pin keyCode={handleSetKey} prevState={handlePrevFlowState} nextState={handleNextFlowState} texts={texts} />
            </Route>

            <Route path="/ScanRFID">
              <ScanRFID keyCode={handleSetKey} prevState={handlePrevFlowState} nextState={handleNextFlowState} texts={texts} />
            </Route>

            <Route path="/ScanQR">
              <ScanQR keyCode={handleSetKey} prevState={handlePrevFlowState} nextState={handleNextFlowState} texts={texts} />
            </Route>

            <Route path="/Home">
              {homeUI.MANT &&
                <div className="main-menu">
                  <div className="logo-menu">
                    <img className="ilock-app-logo" src={mainLogo} alt="ilock-app-logo" />
                    <div className="button-menu">
                      <Typography variant="h1" color="inherit" display="inline" align="center" className="info-widget">
                        {texts.messages.maintenance}
                      </Typography>
                    </div>
                  </div>
                </div>}

              {!homeUI.MANT &&
                <div className="main-menu">
                  {!station.menuImageUrl && <div className="logo-menu">
                    <img className="ilock-app-logo" src={mainLogo} alt="ilock-app-logo" />
                    <div className="qr-download">
                      <img src={QRlogo} alt="QRLogo" className="qr-download-image" id='QRLogo' />
                      <Typography variant="h4" color="inherit" display="inline" align="center" className="info-widget">
                        {texts.messages.scan_me}
                      </Typography>
                    </div>
                  </div>}
                  {station.menuImageUrl && <div className="logo-menu">
                    <img src={station.menuImageUrl} alt="menuImageUrl" className="ilock-app-logo" id='menuImageUrl' />
                  </div>}
                  <Home goFlow={handleGoFlow} ui={homeUI} texts={texts} />
                  <div />
                </div>}

              <div className="ilock-version">
                <div>{versionId}</div>
                <div>{clockState}</div>
              </div>
            </Route>

            <Redirect to={redirect} />
          </div>}
      </ThemeProvider>
    </Router>
  );
}