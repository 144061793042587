import React, { useState, useEffect, useRef } from 'react';

//UI
import '../App.css';
import Typography from '@material-ui/core/Typography';
import QRlogo from '../images/QRlogo.png';
import CircularProgress from '@material-ui/core/CircularProgress';
import BottomMenu from '../components/BottomMenu';

//CUSTOM REQUIRES
var styles = require('../styles.js');
var lockServer = require('../tools/LockServer.js');
var timer;

//METHODS
export default function ScanQR(props) {
  const [loading, setLoading] = useState(false);
  const [remainingTime, setRemainingTime] = useState();
  const [key, setKey] = useState("");
  const mainRef = useRef(null);

  async function onKeyPress(e) {
    setKey(key + e.key);
    if (e.key === "Enter") {
      mainRef.current.blur();
      lockServer.lightOff();
      clearInterval(timer);
      setLoading(true);
      setRemainingTime(10);
      await props.keyCode(key);
      setLoading(false);
    }
  }

  useEffect(() => {
    let pendingTime = 60;
    setRemainingTime(pendingTime);
    lockServer.lightOn();
    timer = setInterval(async () => {
      mainRef.current.focus();
      setRemainingTime(pendingTime--);
      if (pendingTime < 0) {
        handlePrevState();
      }
    }, 1000);
    return () => clearInterval(timer);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function handlePrevState() {
    mainRef.current.blur();
    lockServer.lightOff();
    clearInterval(timer);
    props.prevState();
  }

  return (
    <div className="widget-menu">
      <div></div>

      <div className="center-widget">
        <Typography variant="h1" color="inherit" display="inline" align="center" className="info-widget">
          {props.texts.messages.scan_qr_code}
        </Typography>

        <img src={QRlogo} alt="QRLogo" className="scan-logo" ref={mainRef} onKeyPress={onKeyPress} tabIndex={0} />

        {!loading &&
          <Typography variant="subtitle1" color="inherit" display="inline" align="center">
            {props.texts.messages.window_will_close.replace("$time", remainingTime)}
          </Typography>}

        {loading && <CircularProgress size='7vmin' style={{ marginTop: '25px', color: 'rgb(0,2,58)' }} />}

      </div>

      <BottomMenu prevState={handlePrevState} hideNextButton={true} texts={props.texts} />
    </div>
  );
}