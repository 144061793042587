//UTILS
import React from 'react';

//UI
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import { MdKeyboard } from "react-icons/md";

//CUSTOM STYLES
var styles = require('../styles.js');
const ButtonLock = withStyles(styles.ButtonLock)(Button);
const button_spacing = 2;

//METHODS
export default function LockersGrid(props) {
    function getColorByStatus(lock) {
        if (props.selectedLocker.num === lock.num)
            return "rgb(0,2,58)";
        else
            switch (lock.status) {
                case 'dirty':
                case 'free':
                    return "rgb(16,158,154)";
                case 'in_use':
                    return "gray";
                case 'blocked':
                    return "red";
                case 'disabled':
                    return "gray";
                case 'hidden':
                    return "transparent";
                case 'control':
                    return "transparent";
                default:
                    return "gray"
            }
    }

    return (
        <div style={{ height: 42 + 'vh' }}>
            {props.locksGrid.map((row) => (
                <Grid container key={props.locksGrid.indexOf(row)} justifyContent="center" spacing={button_spacing}>
                    {row.map((lock) => (
                        <Grid key={props.locksGrid.indexOf(row) + row.indexOf(lock)} item>
                            <ButtonLock
                                className="no-focus-button"
                                disableRipple
                                disabled={lock.status === "hidden"}
                                style={{
                                    backgroundColor: getColorByStatus(lock),
                                    opacity: (props.selectedLocker.num === lock.num) || (lock.highLight === true) ? 1.0 : .30,
                                    //color: (props.selectedLocker.num === lock.num) ? "rgb(16,158,154)" : "",
                                    //border: (props.selectedLocker.num === lock.num) ? '3px solid rgb(160,0,15)' : ""
                                }}
                                onClick={() => props.clickedLocker(lock)}
                            >
                                {lock.status === "hidden" ? "" : lock.status === "control" ? <MdKeyboard style={{ color: 'gray', fontSize: "6vmin" }} /> : lock.name}
                            </ButtonLock>
                        </Grid>
                    ))}
                </Grid>
            ))}
        </div>
    );
};