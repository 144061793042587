//UTILS
import { React, useEffect, useState } from 'react';

//UI
import '../App.css';
import { ThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//CUSTOM STYLES
import BottomMenu from '../components/BottomMenu';
var styles = require('../styles.js');
const ButtonFlat = withStyles(styles.ButtonFlat)(Button);

//METHODS
export default function Collect(props) {
  const [seconds, setSeconds] = useState(60);
  const [isDirtyModal, setIsDirtyModal] = useState(false);


  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0)
        setSeconds(seconds => seconds - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);

  if (seconds === 0)
    props.prevState();

  if (isDirtyModal === false)
    return (
      <div className="pin-menu">
        <div className="left-menu">
          <div />

          <Typography variant="h1" color="inherit" display="inline" align="center">
            {props.texts.messages.your_locker}
          </Typography>

          <Button style={styles.buttonBig} disableRipple variant="outlined" color="primary">{props.lock.name}</Button>

          <ThemeProvider theme={styles.theme}>
            <Typography variant="subtitle1" color="inherit" display="inline" align="center">
              {props.texts.messages.window_will_close.replace("$time", seconds)}
            </Typography>
          </ThemeProvider>

          <div />
        </div>
        <div>
          <div className="button-menu">
            <ButtonFlat onClick={props.prevState}>
              {props.texts.buttons.cancel.toUpperCase()}
            </ButtonFlat>
          </div>

          <div className="button-menu">
            {props.ui?.KEEP && <ButtonFlat onClick={props.openLock}>
              {props.texts.buttons.open_and_keep.toUpperCase()}
            </ButtonFlat>}
          </div>

          <div className="button-menu">
            <ButtonFlat onClick={() => props.ui?.DIRTY === true && props.lock.status !== "dirty" ? setIsDirtyModal(true) : props.newCollect(props.lock.status === "dirty")} style={{ background: "rgb(16,158,154)" }}>
              {props.texts.buttons.leave.toUpperCase()}
            </ButtonFlat>
          </div>

        </div>
        <div />
      </div>
    );
  else if (isDirtyModal === true)
    return (
      <div className="widget-menu">

        <div />

        <div className="center-widget" onClick={() => props.goHome()}>
          <Typography variant="h1" color="inherit" display="inline" align="center" className="info-widget">
            {props.texts.messages.is_dirty}
          </Typography>
        </div>

        <BottomMenu prevState={() => props.newCollect()} nextState={() => props.newCollect(true)} prevText={props.texts.words.no.toUpperCase()} nextText={props.texts.buttons.dirty} texts={props.texts} />

      </div>
    );
};