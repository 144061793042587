//UTILS
import { React, useState } from 'react';

//UI
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

//CUSTOM STYLES
var styles = require('../styles.js');

//METHODS
export default function StationRegister(props) {
    const [stationID, setStationID] = useState('');
    const [apiKey, setApiKey] = useState('');

    const onRegister = (e) => {
        props.register(stationID, apiKey);
    }

    const onCheck = (e) => {
        props.check(stationID);
    }

    const ButtonBasic = withStyles(styles.ButtonBasic)(Button);

    return (
        <div className="main-menu">

            <div></div>

            <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center', margin: '15px' }}>

                <TextField
                    className="button-menu"                    
                    id="outlined-error-helper-text"
                    label={"Station ID"}
                    variant="outlined"
                    onChange={(event) => setStationID(event.target.value)}
                    InputProps={{
                        style: { fontSize: '5vmin' }
                    }}
                    InputLabelProps={{
                        style: { fontSize: '4vmin' }
                    }}
                />

                <TextField
                    className="button-menu"                    
                    id="outlined-error-helper-text"
                    label={"api Key"}
                    variant="outlined"
                    onChange={(event) => setApiKey(event.target.value)}
                    InputProps={{
                        style: { fontSize: '5vmin' }
                    }}
                    InputLabelProps={{
                        style: { fontSize: '4vmin' }
                    }}
                />


                
                    <ButtonBasic
                        className="button-menu"
                        onClick={onCheck}>
                        CHECK
                    </ButtonBasic>
                


                
                    <ButtonBasic
                        className="button-menu"
                        onClick={onRegister}
                        disabled={!props.isDBok}>
                        REGISTER
                    </ButtonBasic>                
            </div>

            <div></div>

        </div>);
}