//UTILS
import React, { useEffect, useState } from 'react';

//UI
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import BottomMenu from '../components/BottomMenu';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockersGrid from '../components/LockersGrid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

//CUSTOM STYLES
var styles = require('../styles.js');
const ButtonModal = withStyles(styles.ButtonModal)(Button);

//GLOBALS
let timeout = 60;
let time = timeout;
let lock = 0;

//METHODS
export default function Deposit(props) {
  const [remainingTime, setRemainingTime] = useState(timeout);
  const [locksFree, setLocksFree] = useState([]);
  const [locksGrid, setLocksGrid] = useState([]);
  const [selectedLocker, setSelectedLocker] = useState();
  const [page, setPage] = useState(1);
  const [openPopup, setOpenPopup] = useState(false);

  function refreshLockGrid() {
    try {
      let tmpLockers = [];
      for (let row = 1; row <= props.grid.rows; row++) {
        let tmpRow = [];
        for (let col = 1; col <= props.grid.columns; col++) {
          let tmpLock = props.locks.find(lock => lock.grid.page === page && lock.grid.row === row && lock.grid.column === col);
          if (tmpLock)
            tmpRow.push(tmpLock);
          else
            tmpRow.push({ status: 'hidden' });
        }
        tmpLockers.push(tmpRow);
      }
      setLocksGrid(tmpLockers);
    }
    catch (e) { console.log("Locker Grid is not set propoerly.") }
  }

  useEffect(() => {
    let tmpFreeLocks = props.locks.filter(lock => (['in_use', 'block'].includes(lock.status)) /*&& !lock.KEY.isRemote*/);
    setLocksFree(tmpFreeLocks);
    if (tmpFreeLocks[lock]) {
      props.selectedLock(tmpFreeLocks[lock]);
      setSelectedLocker({ num: -1 });
      refreshLockGrid();
    }

    if (tmpFreeLocks.length <= 0) {
      time = 6;
      props.noLocks();
    }

    const timer = setInterval(() => {
      time--;

      setRemainingTime(time);

      if (time === 0) {
        props.prevState();
        clearInterval(timer);
      }
    }, 1000);
    return () => { time = timeout; clearInterval(timer); }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    refreshLockGrid();
  }, [page]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    time = timeout;
    setRemainingTime(time);
  }, [selectedLocker]);

  function handleNextPage() {
    if (page === props.grid.pages)
      setPage(1);
    else
      setPage(page + 1);
  }

  function handlePrevPage() {
    if (page === 1)
      setPage(props.grid.pages);
    else
      setPage(page - 1);
  }

  function cancelResetLock() {
    props.selectedLock({ num: -1 });
    setSelectedLocker({ num: -1 });
  }

  function clickedLocker(lock) {
    if (selectedLocker.num === lock.num) {
      props.selectedLock({ num: -1 });
      setSelectedLocker({ num: -1 });
    }
    else if ((['in_use', 'block'].includes(lock.status)) /*&& (!lock.KEY.isRemote)*/) {
      setOpenPopup(true);
      props.selectedLock(lock);
      setSelectedLocker(lock);
    }
  }

  function resetLock() {
    props.selectedLock({ num: -1 });
    setSelectedLocker({ num: -1 });
    props.resetLock()

    if (locksFree.length > 1)
      setLocksFree(locksFree.filter((item) => item.num !== selectedLocker.num));
    else
      props.prevState();
  }

  if ((locksFree.length <= 0) || (locksGrid.length === 0)) {
    if (time > 6) {
      time = 6;
      setRemainingTime(time);
    }
    return (
      <div className="widget-menu">
        <div></div>

        <div className="center-widget" onClick={() => props.prevState()}>
          <Typography variant="h1" color="inherit" display="inline" align="center" className="info-widget">
            {props.texts.messages.no_free_lockers}
          </Typography>

          <Typography variant="subtitle1" color="inherit" display="inline" align="center">
            {props.texts.messages.window_will_close.replace("$time", remainingTime)}
          </Typography>
        </div>

        <div></div>

        <BottomMenu prevState={props.prevState} hideNextButton={true} texts={props.texts} />

      </div>)
  }

  else if ((locksFree.length > 0) && (locksGrid.length > 0)) return (
    <div className="widget-menu">

      <div></div>

      <div className="center-widget">
        <div className="door-selector">
          {props.grid.pages > 1 && page > 1 &&
            <IconButton disableRipple className="no-focus-button" style={styles.buttonArrow} onClick={handlePrevPage}>
              <KeyboardArrowLeftIcon style={{ fontSize: "20vmin" }} />
            </IconButton>}

          {/*<Button className="no-focus-button" style={styles.buttonBig} disableRipple variant="outlined" color="primary">{locksFree[lock].name}</Button>*/}
          <LockersGrid locksGrid={locksGrid} clickedLocker={clickedLocker} selectedLocker={selectedLocker} page={page} grid={props.grid} />

          {props.grid.pages > 1 && page < props.grid.pages &&
            <IconButton disableRipple className="no-focus-button" style={styles.buttonArrow} onClick={handleNextPage}>
              <KeyboardArrowRightIcon style={{ fontSize: "20vmin" }} />
            </IconButton>}
        </div>

        <Typography variant="subtitle1" color="inherit" display="inline" align="center">
          {props.texts.messages.window_will_close.replace("$time", remainingTime)}
        </Typography>
      </div>

      <div></div>

      <BottomMenu prevState={props.prevState} hideNextButton={true} texts={props.texts} />

      {selectedLocker.KEY && <Dialog
        maxWidth={false}
        fullWidth={false}
        open={openPopup}
        onClose={() => setOpenPopup(false)}
      >
        <DialogTitle id="alert-dialog-title">
          <div style={{ padding: '2vmin' }}>
            <Typography variant="h2">
              {props.texts.buttons.delete_deposit}
            </Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1" align="center" style={{ color: 'gray' }}>
            {props.texts.words.user}: <b>{selectedLocker.KEY.name}</b><br />{props.texts.words.time}: <b>{parseInt((new Date().getTime() - selectedLocker.time) / 60000)}min</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <ButtonModal onClick={() => cancelResetLock()} color="primary">
            {props.texts.buttons.cancel.toUpperCase()}
          </ButtonModal>
          <ButtonModal onClick={() => resetLock()} color="primary" style={{ backgroundImage: "linear-gradient( 20deg, rgb(216,158,154) 0%, rgb(200,2,58) 100%)" }}>
            {props.texts.buttons.delete.toUpperCase()}
          </ButtonModal>
        </DialogActions>
      </Dialog>}
    </div>
  );
  else if ((time >= timeout) && ((locksFree.length <= 0) || (locksGrid.length === 0))) return (
    <div className="widget-menu">

      <div></div>

      <div className="center-widget">
        <CircularProgress />
      </div>

      <div></div>

    </div>);
};